import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Last day of Deload Week`}</em></p>
    <p>{`DB Hang Squat Cleans 3×12`}</p>
    <p>{`Ball Hamstring Curls 3×12`}</p>
    <p>{`then,`}</p>
    <p>{`3 Rounds for time:`}</p>
    <p>{`10/Arm Single Arm KB Power Clean (53/35)`}</p>
    <p>{`15 KB Goblet Squats (53/35)`}</p>
    <p>{`1/2 Block Run to Back Lot`}</p>
    <p>{`140ft Sled Pull (pull sled + 135/90`}{`#`}{`)`}</p>
    <p>{`1/2 Block Run to Front`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      